import React from "react";

import { ExpertStatusProps } from "./types";
import "./styles.scss";

const ExpertStatus = ({ status }: ExpertStatusProps) => {
  switch (status) {
    case "available":
      return <div className="expert-status__isonline">Сейчас онлайн</div>;
    case "busy":
      return <div className="expert-status__isoffline">Проводит консультацию</div>;
    case "offline":
    default:
      return <div className="expert-status__isoffline">Сейчас офлайн</div>;
  }
};

export default ExpertStatus;
