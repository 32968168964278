import { useContext } from "react";

import UserContext from "@/contexts/User/UserContext";

export const useBanners = () => {
  const { isUserLoggedIn, freeMinutesCount } = useContext(UserContext);

  const isEnvironmentAllowed = (showOnProd: boolean | null) =>
    process.env.ENV === "prod" && !showOnProd;

  const isBannerActive = (bannerEnvVar: string | undefined, isShown: boolean | null) =>
    bannerEnvVar === "true" && isShown;

  const isFreeSession = () => {
    if (!freeMinutesCount) return false;
    return freeMinutesCount <= 7;
  };

  const checkBannerStatus = (
    bannerEnvVar: string | undefined,
    isShown: boolean | null,
    onlyForAuthorized: boolean | null,
    showOnProd: boolean | null,
    withFreeSession: boolean | null,
  ) => {
    if (isEnvironmentAllowed(showOnProd) || !isBannerActive(bannerEnvVar, isShown)) {
      return null;
    }

    if (onlyForAuthorized && !withFreeSession) {
      return isUserLoggedIn && !isFreeSession() && isBannerActive(bannerEnvVar, isShown);
    }
    if (withFreeSession && !onlyForAuthorized) {
      return isFreeSession() && isBannerActive(bannerEnvVar, isShown);
    }
    if (withFreeSession && onlyForAuthorized) {
      return isUserLoggedIn && isFreeSession() && isBannerActive(bannerEnvVar, isShown);
    }
    return isBannerActive(bannerEnvVar, isShown);
  };

  const checkIsCatalogBannerActive = (
    isShown: boolean | null,
    onlyForAuthorized: boolean | null,
    showOnProd: boolean | null,
    withFreeSession: boolean | null,
  ) =>
    checkBannerStatus(
      process.env.GATSBY_IS_EXPERT_LISTING_BANNER_ACTIVE,
      isShown,
      onlyForAuthorized,
      showOnProd,
      withFreeSession,
    );

  const checkIsWideBannerActive = (
    isShown: boolean | null,
    onlyForAuthorized: boolean | null,
    showOnProd: boolean | null,
    withFreeSession: boolean | null,
  ) =>
    checkBannerStatus(
      process.env.GATSBY_IS_WIDE_BANNER_ACTIVE,
      isShown,
      onlyForAuthorized,
      showOnProd,
      withFreeSession,
    );

  return { checkIsCatalogBannerActive, checkIsWideBannerActive };
};
