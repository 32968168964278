import React, { FC, useContext } from "react";

import { navigate } from "gatsby";

import { isUrlExists } from "@/components/catalog/listing/expertListingBanner/utils";
import { UserContextType } from "@/contexts/User/types";
import UserContext from "@/contexts/User/UserContext";
import { useMarketingHook } from "@/marketing/marketingHook";

import { BannerBlockProps } from "./types";

import "./styles.scss";

const BannerBlock: FC<BannerBlockProps> = ({ wideBannerData }) => {
  const { isUserLoggedIn } = useContext<UserContextType>(UserContext);
  const {
    wideBannerDesktopImage,
    wideBannerMobileImage,
    wideBannerTabletImage,
    wideBannerIsShownOnlyForAuthorized,
    wideBannerUrl,
    wideBannerMetric,
    wideBannerHeight,
  } = wideBannerData;

  const { userID } = useContext(UserContext);
  const { marketingStepsForFixPaymentFromLandingPage, customMarketingHandler } = useMarketingHook();

  const onClickHandler = () => {
    if (wideBannerUrl) navigate(wideBannerUrl);
    if (wideBannerMetric?.metricId) {
      customMarketingHandler(wideBannerMetric?.metricId);
    }
    // todo: uncomment when chained analytics is needed
    // marketingStepsForFixPaymentFromLandingPage({
    //   userId: userID?.toString(),
    //   bannerName: "secret_ideal_stretching_banner_5000",
    // });
  };

  if (wideBannerIsShownOnlyForAuthorized && !isUserLoggedIn) return null;

  return (
    <div className="banner-block__container">
      <picture style={wideBannerHeight ? { height: wideBannerHeight } : {}} className="banner-block" onClick={onClickHandler}>
        {isUrlExists(wideBannerMobileImage) && (
        <source
          srcSet={`${wideBannerMobileImage?.oneX.data?.attributes?.url} 1x,
              ${wideBannerMobileImage?.twoX.data?.attributes?.url} 2x,
              ${wideBannerMobileImage?.threeX?.data?.attributes?.url} 3x`}
          media="(max-width: 767px)"
        />
        )}
        {isUrlExists(wideBannerTabletImage) && (
        <source
          srcSet={`${wideBannerTabletImage?.oneX.data?.attributes?.url} 1x,
              ${wideBannerTabletImage?.twoX.data?.attributes?.url} 2x,
              ${wideBannerTabletImage?.threeX?.data?.attributes?.url} 3x`}
          media="(max-width: 1199px)"
        />
        )}
        {isUrlExists(wideBannerDesktopImage) && (
        <source
          srcSet={`${wideBannerDesktopImage?.oneX.data?.attributes?.url} 1x,
              ${wideBannerDesktopImage?.twoX.data?.attributes?.url} 2x,
              ${wideBannerDesktopImage?.threeX?.data?.attributes?.url} 3x`}
          media="(min-width: 1200px)"
        />
        )}
        <img src={wideBannerMobileImage?.oneX.data?.attributes?.url} alt="" />
      </picture>
    </div>
  );
};

export default BannerBlock;
