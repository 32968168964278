import React, { useContext } from "react";

import { SessionType } from "@/autoGeneratedGlobalTypes";
import { Button, ButtonColorEnum, ButtonIconPositionEnum } from "@/components/common/button";
import { ConditionalTooltip, TooltipPositionEnum } from "@/components/common/conditionalTooltip";
import { IconTypeEnum } from "@/components/common/icon";
import { UNSPECIFIED_RATE_GAG_RUB } from "@/components/constants";
import GlobalContext from "@/contexts/Global/GlobalContext";
import useCallClick from "@/hooks/useCallClick";
import { useMarketingHook } from "@/marketing/marketingHook";
import { canUserCall, isActionActiveForUser, isCallTypeAvailable, isLoggedInAndHasFreeMinutes } from "@/utils/commonUtils";

import BonusText from "./bonusText";
import { CallButtonsTypes } from "./types";
import "./styles.scss";

const CallButtons = ({
  expertId,
  expertRates,
  expertState,
  freeMinutesCount,
  isUserLoggedIn,
  trialAllowance,
}: CallButtonsTypes) => {
  const { customMarketingHandler } = useMarketingHook();

  const { currentSessionData } = useContext(GlobalContext);
  const {
    callClickHandler: videoCallClickHandler,
  } = useCallClick(expertId, SessionType.VIDEO_CALL, trialAllowance?.video);
  const {
    callClickHandler: phoneCallClickHandler,
  } = useCallClick(expertId, SessionType.PHONE_CALL, trialAllowance?.phone);
  const {
    callClickHandler: chatClickHandler,
  } = useCallClick(expertId, SessionType.CHAT, trialAllowance?.chat);

  const userCanCallFlag = canUserCall(expertState, currentSessionData?.status);
  const isVideoCallAvailable = isCallTypeAvailable(expertRates.videoCall);
  const isPhoneCallAvailable = isCallTypeAvailable(expertRates.phoneCall);
  const isChatAvailable = process.env.GATSBY_CHAT_ENABLED === "true" && isCallTypeAvailable(expertRates.chat);
  const isVideoPriceCrossedOut = trialAllowance?.video && isLoggedInAndHasFreeMinutes(isUserLoggedIn, freeMinutesCount);
  const isChatPriceCrossedOut = trialAllowance?.chat && isLoggedInAndHasFreeMinutes(isUserLoggedIn, freeMinutesCount);
  const isChatFFIsOn = process.env.GATSBY_CHAT_IS_ON === "true";

  return (
    <>
      <div className="call-buttons__buttons">
        {isVideoCallAvailable && (
        <div className={`call-buttons__button${
          isActionActiveForUser(isUserLoggedIn, freeMinutesCount)
            ? " is-action"
            : ""
        }`}
        >
          <ConditionalTooltip
            position={TooltipPositionEnum.Top}
            text="Эксперт сейчас недоступен. Выберите другого или попробуйте позже"
            isHoverEvent
            minWidth="210px"
            showTooltipFlag={!userCanCallFlag}
          >
            <Button
              text="Видео"
              subtext={(
                <span className={`${isVideoPriceCrossedOut ? "call-buttons__line-through" : ""} `}>
                  {expertRates.videoCall !== -1
                    ? expertRates.videoCall
                    : UNSPECIFIED_RATE_GAG_RUB}
                    &nbsp;
                  <span className="call-buttons__small-spacing">₽/</span>
                  мин
                </span>
                )}
              color={ButtonColorEnum.Dark}
              disabled={!userCanCallFlag}
              onClick={() => {
                customMarketingHandler("video_call_button_click");
                videoCallClickHandler();
              }}
              icon={IconTypeEnum.VideoBold}
              iconPosition={ButtonIconPositionEnum.Left}
              iconViewBox="0 0 22 22"
            />
          </ConditionalTooltip>
        </div>
        )}
        {isPhoneCallAvailable && (
        <div className={`call-buttons__button${
          isActionActiveForUser(isUserLoggedIn, freeMinutesCount)
            ? " is-action"
            : ""
        }`}
        >
          <ConditionalTooltip
            position={TooltipPositionEnum.Top}
            text="Эксперт сейчас недоступен. Выберите другого или попробуйте позже"
            isHoverEvent
            minWidth="210px"
            showTooltipFlag={!userCanCallFlag}
          >
            <Button
              text="Телефон"
              subtext={(
                <span>
                  {expertRates.phoneCall !== -1
                    ? expertRates.phoneCall
                    : UNSPECIFIED_RATE_GAG_RUB}
                  &nbsp;
                  <span className="call-buttons__small-spacing">₽/</span>
                  мин
                </span>
              )}
              color={ButtonColorEnum.Dark}
              disabled={!userCanCallFlag}
              onClick={() => {
                customMarketingHandler("phone_call_button_click");
                phoneCallClickHandler();
              }}
              icon={IconTypeEnum.PhoneBold}
              iconPosition={ButtonIconPositionEnum.Left}
              iconViewBox="0 0 23 22"
            />
          </ConditionalTooltip>
        </div>
        )}
        {isChatFFIsOn && isChatAvailable
        && (
          <div className={`call-buttons__button${
            isActionActiveForUser(isUserLoggedIn, freeMinutesCount)
              ? " is-action"
              : ""
          }`}
          >
            <ConditionalTooltip
              position={TooltipPositionEnum.Top}
              text="Эксперт сейчас недоступен. Выберите другого или попробуйте позже"
              isHoverEvent
              minWidth="210px"
              showTooltipFlag={!userCanCallFlag}
            >
              <Button
                text="Чат"
                subtext={(
                  <span className={`${isChatPriceCrossedOut ? "call-buttons__line-through" : ""} `}>
                    {expertRates.chat !== -1
                      ? expertRates.chat
                      : UNSPECIFIED_RATE_GAG_RUB}
                      &nbsp;
                    <span className="call-buttons__small-spacing">₽/</span>
                    мин
                  </span>
                  )}
                color={ButtonColorEnum.Dark}
                disabled={!userCanCallFlag}
                onClick={() => {
                  customMarketingHandler("chat_button_click");
                  chatClickHandler();
                }}
                icon={IconTypeEnum.Chat}
                iconPosition={ButtonIconPositionEnum.Left}
              />
            </ConditionalTooltip>
          </div>
        )}
      </div>
      <BonusText
        expertRates={expertRates}
        isUserLoggedIn={isUserLoggedIn}
        freeMinutesCount={freeMinutesCount}
        trialAllowance={trialAllowance}
      />
    </>
  );
};

export default CallButtons;
