import React from "react";

import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import { getFreeMinutesForUser, isCallTypeAvailable } from "@/utils/commonUtils";
import { declenateWord } from "@/utils/stringUtils";

import { BonusTextProps } from "./types";
import "./styles.scss";

const BonusText = ({
  expertRates,
  freeMinutesCount,
  isUserLoggedIn,
  trialAllowance,
}: BonusTextProps) => {
  const freeMinutesForUser = getFreeMinutesForUser(isUserLoggedIn, freeMinutesCount);
  const newUserFreeMinutesCount = parseInt(process.env.GATSBY_FREE_MINUTES_COUNT ?? "0", 10);

  const isLabelFullWidth = (!isCallTypeAvailable(expertRates.videoCall) || trialAllowance?.video)
                           && !isCallTypeAvailable(expertRates.phoneCall)
                           && (!isCallTypeAvailable(expertRates.chat) || trialAllowance?.chat);

  if ((isUserLoggedIn && !freeMinutesCount)) {
    return null;
  }

  const callTypes = isLabelFullWidth
    ? [{
      isShown: isCallTypeAvailable(expertRates.chat) ? expertRates.chat : expertRates.videoCall,
      isFirstSessionWithoutPaymentAllowed: isCallTypeAvailable(expertRates.chat)
        ? trialAllowance?.chat : trialAllowance?.video,
    }]
    : [
      {
        isShown: isCallTypeAvailable(expertRates.videoCall),
        isFirstSessionWithoutPaymentAllowed: trialAllowance?.video,
      },
      {
        isShown: isCallTypeAvailable(expertRates.phoneCall),
        isFirstSessionWithoutPaymentAllowed: false,
      },
      {
        isShown: process.env.GATSBY_CHAT_ENABLED === "true" && isCallTypeAvailable(expertRates.chat),
        isFirstSessionWithoutPaymentAllowed: trialAllowance?.chat,
      },
    ];

  return (
    <div className="bonus-text">
      {callTypes.map(({ isShown, isFirstSessionWithoutPaymentAllowed }) => {
        if (!isShown) {
          return null;
        }
        return (
          <div className="bonus-text__item">
            {isFirstSessionWithoutPaymentAllowed && (
              <>
                <Icon type={IconTypeEnum.Gift} size={IconSizeEnum.Size20} />
                {freeMinutesForUser === newUserFreeMinutesCount ? (
                  <>Бесплатно</>
                ) : (
                  <>
                    {freeMinutesForUser}
                    {" "}
                    {declenateWord(freeMinutesForUser, ["бонусная минута", "бонусных минуты", "бонусных минут"])}
                  </>
                )}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default BonusText;
