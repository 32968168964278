import React from "react";

import { Tooltip, TooltipPositionEnum } from "@components/common/tooltip";

import { ConditionalTooltipProps } from "./types";

/**
 * HOC that displays component with tooltip or without depending on showTooltipFlag
 * @param props
 * @returns
 */
export const ConditionalTooltip = (props: ConditionalTooltipProps) => {
  const {
    text, position, children, isHoverEvent, minWidth, showTooltipFlag,
  } = props;

  if (showTooltipFlag) {
    return (
      <Tooltip
        text={text}
        position={position}
        isHoverEvent={isHoverEvent}
        minWidth={minWidth}
      >
        {children}
      </Tooltip>
    );
  }

  return children;
};

export { TooltipPositionEnum };
