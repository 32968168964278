import React, { useMemo } from "react";

import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import { roundToDecimal } from "@/utils/numberUtils";

import { RatingProps } from "./types";
import "./styles.scss";

const Rating = ({ value }: RatingProps) => {
  const colorClassName = useMemo(() => {
    switch (true) {
      case value < 3:
        return "red";
      case value < 4:
        return "yellow";
      default:
        return "green";
    }
  }, [value]);

  return (
    <div className={`rating ${colorClassName}`}>
      <div>{roundToDecimal(value, 1)}</div>
      <Icon type={IconTypeEnum.Fav} size={IconSizeEnum.Size16} />
    </div>
  );
};

export default Rating;
